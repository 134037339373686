.Leave-section {
  display: flex;
  margin: 10px 0;
  gap: 1%;
  width: 100%;
}
.Leaves-contaier {
  flex-grow: 1;
  margin-top: 15px;
  padding: 10px 0 0 10px;
  & h4,
  span,
  h5 {
    color: var(--initial-color);
  }
  & h3 {
    color: var(--active-color);
  }
}
.Leaves-component-section {
  margin: 35px 0;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.Leaves-section-container {
  margin: 2% 1%;
  display: flex;
  align-items: center;
  gap: 7%;
  padding: 20px 9px;
  flex-grow: 1;
  & span {
    line-height: 13px;
  }
}
.leaces-icons {
  padding: 7px;
  border-radius: 50%;
  color: var(--color);
}


.apply-leave-container {
  width: 270px;
}
.Apply-leave-from {
padding: 5px;
& label {
    color: var(--initial-color);
    display: flex;
    flex-direction: column;
    font-size: 60%;
    margin-top: 10px;
}
& input,select,textarea {
    padding: 7px;
    border: none;
    border-radius: 10px;
    border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
    outline: none;
}
& textarea {
    resize: none;
}
& div {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    & label {
        width: 47%;
        overflow: hidden;
        & input, select, option {
            width: 100%;
            color: var(--shadow-resting-medium);
        }
    }
    & select {
      cursor: pointer;
    }
}
& button {
    width: 48%;
    margin: 3px auto;
}
& section {
    display: flex;
    justify-content: space-between;
    & button {
      width: 30%;
    }
}
}

.section-button {
  font-size: 80%;
  border-radius: 10px;
  background: transparent;
  border: 0.1px dotted lightgray;
  padding: 7px 3px;
  cursor: pointer;
  color: var(--shadow-resting-medium);
}



.Leaves-report-component {
  display: flex;
  max-width: 100%;
  height: fit-content;
  & table {
    width: 100%;
    text-align: left;
    margin: 10px 0;

    & th,
    td, p {
      text-align: center;
      padding: 10px 0;
      color: var(--active-color);
      font-size: 70%;
      font-weight: 100;
      border-bottom-style: solid;
      border-bottom-color: rgb(242, 242, 242);
      border-bottom-width: 2px;
    }
    & td {
        font-size: 85%;
        /* font-weight: 400; */
        font-family: 'Times New Roman', Times, serif;
    }
  }
}

@media (max-width: 480px) {
  .Leave-section {
    flex-direction: column;
  }
  .Leaves-contaier {
    margin-top: 0;
  }
  .Leaves-component-section {
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .Leaves-section-container {
    margin-top: 0;
    margin-bottom: 5px;
    padding: 3% 4%;
  }
  .apply-leave-container {
    width: 97%;
    margin: auto;
  }
}
