.notification-heading {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px;
  border-bottom-style: solid;
  border-bottom-color: rgb(219, 219, 219);
  border-bottom-width: 1px;
  position: sticky;
  top: 0;
  background: white;
  & h5 {
    flex-grow: 1;
    text-align: center;
  }
}
.notificaton-FaArrowLeft {
  padding: 4px;
}
.notification-card {
  width: 100%;
  margin-top: 5px;
  font-size: 90%;
  padding: 4px 0;
  cursor: pointer;
  &:active {
    .notification-title {
      color: unset;
    }
  }
}
.notification-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-title {
  flex-grow: 1;
  color: var(--sub-heading-color);
  margin-left: 5px;
}

.notification-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.notification-para {
  font-size: 90%;
}

.notification-time {
  font-size: 65%;
}

@media (max-width : 768px) {
  .notification-card {
    margin-top: 5px;
  }
}