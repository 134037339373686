.newsfeed-container {
    max-width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 5px;
    margin-bottom: 30px;
  }  
  .newsfeed-post {
    width: 100%;
    margin-top: 10px;
    position: -webkit-sticky;
  }
  .new-post-action {
    padding: 10px;
    display: flex;
    gap: 15px;
    align-items: center;
    & img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    & input {
      flex: 1;
      height: 30px;
      background: none;
      border-radius: 0 30px 30px;
      border-style: solid;
      border-color: rgb(219, 219, 219);
      border-width: 1px;
    }
  }
  .new-post-types {
    display: flex;
    align-items: center;
    color: var(--initial-color);
    font-size: 90%;
    justify-content: space-around;
    margin-bottom: 5px;
    & div {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
  }

  .newsfeed-content-grid {
    max-width: 100%;
    margin-top: 10px;
    max-height: 100%;
    overflow-y: scroll;
    padding: 0 10px;
  }
  .newfeed-column-container {
    margin-bottom: 20px;
  }
  .newsfeed-img-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & span {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--initial-color);
      & div {
        display: flex;
        flex-direction: column;
      }
    }
    & img {
      width: 40px;
      height: 40px;
    }
  }
  .newsfeed-image {
    width: 100%;
    max-width: 480px;
    margin-top: 5px;
    max-height: 300px;
  }