.homePage-row {
    width: 100%;
    height: 100%;
    display: flex;
}
.homePage-main {
    flex: 1;
   padding-top: 5%;
    overflow-y: scroll;
}
.Home-header-container {
    width: 100%;
    min-height: 350px;
    background-size: cover !important;
    background-position: right !important;
}
.Home-header-container-span {
    width: 100%;
    height: 350px;
    background: rgb(0, 0, 0 , 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.Home-header-container-button {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 15px;
    & button {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 12px 23px;
        gap: 10px;
        background: transparent;
        color: var(--button-color);
        border-radius: var(--borderRadius-medium, 6px);
        border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
        &:hover {
            color: var(--color);
        }
    }
}
.Home-header-bottom-container {
    width: 50%;
    height: 60px;
    background: rgb(255, 255, 255 , 0.2);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    margin-top: -63px;
    margin-left: auto;
    padding: 0 5%;
}
.home-header-bottom-icons {
    color: var(--button-color);
    padding: 3px;
    font-size: 110%;
    cursor: pointer;
    &:hover {
        color: var(--color);
        background: var(--button-color);
    }
    
}

/* ------------Home about -------------------------- */

.Home-about-fragment {
    margin: 10% 0;
}
.Home-about {
    margin: 5% 0;
    text-align: center;
    & h1 {
        color: transparent;
        -webkit-text-stroke: 1px var(--glass-background-color);
        font-size: 500%;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    & h2 {
        color: var(--initial-color);
        font-size: 250%;
        position: relative;
        margin-top: -63px;
        font-family: serif;
    }
}
.Home-about-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Home-about-content-para {
    width: 60%;
    line-height: 30px;
    color: var(--active-color);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.Home-about-content-icons {
    width: 30%;
    & span {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px;
        font-size: 170%;
        margin-top: 10px;
        & h5{
            font-size: 45%;
        }
    }
}

/* ------------------- home contact ----------------------------- */
.container.contact__container{
    width: 95%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;
}
.contact__options{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
}
#contact{
   background-color: rgb(253, 251, 251);
}
.contact__option{
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
}
.contact__option-icon{
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: var(--color-white);
}
.home-contact-form {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    
    & input,textarea{
        padding: 0.5rem;
        border-radius: 0.5rem;
        border-radius: var(--borderRadius-medium, 6px);
        border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
    }
    & button {
        padding: 1rem;
    }
}



.homePage-side-top {
    display: none;
}
.homePage-side {
    margin-left: 5px;
    max-height: 100%;
}
.employee-of-card {
    width: 230px;
    line-height: 10px;
    text-align: center;
    margin-top: 5%;
    & h1 {
        font-size: 100%;
        margin: 10px;
        color: var(--button-color);
    }
    & p {
        font-size: 70%;
        margin: 7px;
        color: var(--active-color);
        font-family: monospace;
    }
    & q {
        font-size: 55%;
        color: var(--initial-color);
    }

}
.profile-image-container {
    width: 95%;
    display: flex;
    align-items: center;
    margin: 5px auto;
    overflow: hidden;
    justify-content: center;
    & img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 2px;
        background: var(--button-color);
    }
    & span {
        background: var(--glass-background-color);
        padding: 5px 17px;
        margin-left: -13px;
        border-radius: 0 25px 25px 0;
        cursor: pointer;
    }
    & h2 {
        white-space: nowrap;
        font-size: 100%;
        margin: 5px 0;
        color: var(--error-color);
        height: fit-content;
    }
    & h6 {
        font-size: 60%;
        color: var(--initial-color);
    }
}

/* ------------Home companies -------------------------- */
.home-companies-main{
    width: 80%;
    display: flex;
    margin: auto;
    flex-direction: row;
    gap: 10px;
}
.home-companies-sub-one,
.home-companies-sub-two {
    width: 35%;
    /* background: rgba(250, 250, 250, 0.6); */
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
        width: 90%;
        height: 80%;
        object-fit: scale-down;
    }
}
.home-companies-sub-two{
    width: 63%;
    padding: 10px 25px;
    min-height: 200px;
    word-break: break-all;
    & span {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-bottom: 10px;
    }
    & h5 {
        color: var(--initial-color);
        font-size: 85%;
    }
}


@media (max-width : 950px) {
    .homePage-row {
        overflow: hidden;
    }
    .homePage-main {
        margin: 5px;
        padding-top: 0;
    }
    .homePage-side-top {
        display: flex;
        flex-wrap: wrap;
    }
    .homePage-side {
        display: none;
    }
    .employee-of-card {
        margin: 5px;
        flex-grow: 1;
    }
}

@media (max-width : 600px) {
    .Home-about {
        & h1 {
            font-size: 400%;
        }
        & h2 {
            font-size: 250%;
            margin-top: -63px;
        }
    }
    .Home-about-content {
        flex-direction: column;
    }
    .Home-about {
    margin: 5% 0;
    text-align: center;
    & h1 {
        color: transparent;
        -webkit-text-stroke: 1px var(--glass-background-color);
        font-size: 500%;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    & h2 {
        color: var(--initial-color);
        font-size: 250%;
        position: relative;
        margin-top: -63px;
        font-family: serif;
    }
}
    .Home-about-content-para {
        text-align: center;
        width: 100%;
        line-height: unset;
        font-weight: 100;
        word-wrap: break-word;
    }
    .Home-about-content-icons {
        width: 100%;
        & span {
            justify-content: center;
        }
    }
    .ourTrusted-div {
        flex-direction: column;
        & div {
            width: 100% !important;
            & p {
                text-align: center !important;
            }
        }
    }
   .home-companies-main{
        width: 95%;
        flex-direction: column;
    }
    .home-companies-sub-one,
.home-companies-sub-two {
    width: 100%;
}
.home-companies-sub-two {
    min-height: 100px;
    padding: 25px 0;
}
}


@media (max-width : 480px) {
    .Home-about {
        & h1 {
            font-size: 330%;
        }
        & h2 {
            font-size: 200%;
            margin-top: -47px;
        }
    }
}