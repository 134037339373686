.task-card-page {
  width: 100%;
  overflow: hidden;
}
.tasks-filter {
  width: 97%;
  display: flex;
  justify-content: end;
  margin: 20px 0;
  & h5 {
    gap: 0;
    padding-right: 0;
  }
  & input, select {
    border: none;
    background: transparent;
    padding-left: 15px;
    padding-right: 5px;
    outline: none;
  }
}
.tasks-tabs {
  width: 100%;
  display: ruby;
  overflow-x: scroll;
  margin: 10px 0;
  & button {
    border: none;
    background: transparent;
    white-space: nowrap;
    margin-right: 10px;
  }
}
.task-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.task-card {
  width: 30%;
  margin-top: 15px;
  padding: 10px;
}
.task-card button.done {
  background-color: #4caf50;
  color: #fff;
}
button.active {
  border-bottom:  var(--button-color) 2px solid;
  color: var(--button-color);
}
.task-card button.not-done {
  background-color: var(--error-color);
  color: #fff;
}
.task-card h3 {
  margin-bottom: 10px;
  color: var(--initial-color);
}
.task-card-h3 {
  display: flex;
  justify-content: space-between;
}

.buttoncont button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}
.task-bottom-pop {
  min-height: 30%;
  padding-bottom: 50px;
  & h4 {
    margin: 4px 0;
    padding: 10px 0 5px 0;
  }
}

@media (max-width : 1000px) {
  .task-card {
    width: 42%;
  }
}
@media (max-width : 480px) {
  .tasks-tabs {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
  }
  .task-card {
    width: 100%;
  }
}