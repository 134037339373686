.gallery-box-scroll{
    height: 100%;
    overflow-y: scroll;
}
.gallery-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 100px;
    align-items: self-start;
    gap: 20px;
}

.gallery-container {
    margin-top: 2%;
    height: 230px;
    cursor: pointer;
    overflow: hidden;
    & h5 {
        color: var(--initial-color);
        position: sticky;
        background: var(--color);
        padding: 1px 5px;
        width: fit-content;
        margin-bottom: -20px;
        font-size: 70%;
    }
    & div {
        width: 100%;
        height: 150px;
        display: flex;
        overflow: scroll;
        display: -webkit-inline-flex;
        & img {
            width: 100%;
            height: 100%;
            transition: transform 0.3s ease;
            transform: scale(0.97);
            z-index: -1;
        }
    }
    & p {
        font-size: 70%;
        margin: 10px 0 0 5px;
        color: var(--initial-color);
    }
        & i {
        font-size: 50%;
        color: var(--sub-heading-faded-color);
        margin-left: 5px;
    }
    & button {
        width: 100%;
    }
    &:hover {
        & img {
            transform: scale(0.99);
        }
    }
}

@media (max-width : 1000px) {
    .gallery-container {
        flex-grow: 1;
    }
}


@media (max-width : 570px) {
    .gallery-box {
        gap: 5px;
    }
    .gallery-container {
        width: 47%;
        padding: 0;
    }
}

@media (max-width : 350px) {
    .gallery-container {
        width: 100%;
    }
}