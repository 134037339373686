/* SalarySlip.css */

.salary-slip {
    max-width: 700px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .salary-slip1 {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  

  

  .slaryinput {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
  } 
  .slarydrop {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  


  