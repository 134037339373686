  .float-button {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 999; /* Ensure it's above other content */
  }
  
  .popup {
    position: fixed;
    bottom: 40px;
    right: 20px;
    max-width: 300px;
    z-index: 999; /* Ensure it's above other content */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .popup-header {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .popup-title {
    font-weight: bold;
  }
  
  .close-btn {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .popup-content {
    padding: 10px;
  }
  
  .message {
    background-color: #f2f2f2;
    padding: 8px;
    margin: 5px 0;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .message:last-child {
    margin-bottom: 0;
  }
  .animated-icon {
    transition: transform 0.3s ease-in-out; /* Example transition */
  }
  
  .animated-icon:hover {
    transform: translateY(-5px); /* Example transformation on hover */
  }
  
  