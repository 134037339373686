.Allempdash-div {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    display: flex;
}

.Allempdash-img-div {
    height: 100%; 
    padding: 10px; 
    text-align: center;
}

.Allempdash-img {
    width: 170px; 
    height: 170px; 
    border-radius: 50%;
}

.Allempdash-cont-div {
    width: 100%;
    height: 100%; 
    padding: 10px;
}

.Allempdash-c-h3 {
    margin-bottom: 10px;
}

.Allempdash-cont-sub-div {
    width: 100%;
    background: whitesmoke;
    margin-top: 10px;
    & h5 {
        padding: 10px 20px;
        font-weight: 100;
        border-bottom: 1px solid gray;
    }
}

.Allempdash-cont-details-div {
    width: 100%;
    min-height: 70%;
    padding: 10px;
    & span {
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
}
.Allempdash-cont-details-div-flex {
    display: flex;
    flex-wrap: wrap;
    & span {
        width: 31.3%;
        margin: 0 1% 2% 0;
        height: 40px;
        display: unset;
        padding-top: unset;
    }
}

@media (max-width : 900px) {
    .Allempdash-cont-details-div-flex {
        & span {
            width: 45%;
        }
    }
}

@media (max-width : 600px) {
    .Allempdash-div {
        width: 97%;
        flex-direction: column;
    }
    .Allempdash-img-div {
        display: flex;
        align-items: center;
        gap: 5%;
    }
    .Allempdash-img {
        width: 110px; 
        height: 110px; 
        border-radius: 50%;
    }
}