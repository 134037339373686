.SearchEmployee-input-span {
  width: 100%;
  height: 30px;
}
.SearchEmployee-icon {
  position: absolute;
  font-size: 90%;
  margin: 7.5px 0 0 5px;
  color: var(--sub-heading-faded-color);
  font-weight: 100;
}
.SearchEmployee-input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
  padding: 5px 0;
  padding-left: 20px;
  &::placeholder {
    font-size: 80%;
    color: var(--initial-color);
  }
}

.search-dropdown {
    overflow-y: scroll;
}

.emp-dropdown {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 4px 2.5%;
    margin-top: 7px;
    cursor: pointer;
    & img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    & div {
        line-height: 13px;
    }
    & h5 {
        color: var(--initial-color);
        &:active {
            color: unset;
        }
    }
    & i {
        font-size: 50%;
    }
}