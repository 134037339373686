.Projects {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
  & h5 {
    color: var(--active-color);
    white-space: nowrap;
  }
  & p {
    font-size: 70%;
    color: var(--initial-color);
  }
  &:active {
    & p,
    h5 {
      color: unset;
    }
  }
}

/* ----------------------------- Project Details ---------------------------- */

.projects-main {
  width: 100%;
}
.projects-main-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-sub-header-cont {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--color);
  & h2 {
    font-size: 250%;
  }
  & p {
    font-size: 80%;
  }
}

.Project-team {
  min-height: 500px;
  width: 100%;
  padding: 5% 0;
  & h1 {
    text-align: center;
  }
}
.project-team-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 5% 3%;
  gap: 15px;
  justify-content: center;
}
.project-team-cards-span {
  width: 200px;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  & img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  & p {
    font-size: 80%;
    color: var(--borderColor-default);
  }
  & button {
    width: 90%;
  }
}
.add-project-visible,
.add-project-visible-768 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding: 4px;
  cursor: pointer;
  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
  & h5 {
    color: var(--active-color);
    white-space: nowrap;
  }
  
}
.add-project-visible-768 {
  display: none;
}

@media (max-width: 768px) {
  .Projects {
    margin: 3px;
    flex-direction: column;
    gap: 0;
    & img {
      width: 35px;
      height: 35px;
    }
    & h5 {
      font-size: 75%;
      width: fit-content;
    }
    & p {
      display: none;
    }
  }
  .add-project-visible {
    display: none;
  }
  .add-project-visible-768 {
    display: flex;
    margin: 3px;
    flex-direction: column;
    gap: 0;
    & img {
      width: 35px;
      height: 35px;
    }
    & h5 {
      font-size: 75%;
      width: fit-content;
    }
  }
}

@media (max-width: 480px) {
  .project-team-cards-span {
    width: 45%;
    & img {
        width: min(100px, 70%);
        height: min(100px, 40%);
        border-radius: 50%;
      }
  }
}
