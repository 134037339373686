.LoginForm-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  background: url(../images/waves.jpg) no-repeat;
  background-size: cover;
}
.LoginForm-content2 ,
.LoginForm-content1{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoginForm-Animation {
text-align: center;
color: var(--active-color);
& p {
  color: var(--initial-color);
  font-size: 80%;
  margin: 10px 0;
}
& img {
  width: 200px;
  height: 200px;
}
}


/* --------------------------------------- forms ----------------------------- */

.login-form-container {
  width: 70%;
  margin: auto;
}
.LoginForm-buttons {
  display: flex;
  justify-content: space-evenly;
  & button {
    min-width: 35%;
  }
}
.admin-login-button {
  background-color: cadetblue !important;
}
.Admin-Login-signUp {
  margin-top: 10%;
  text-align: center;
  font-size: 70%;
  & .Admin-Login-signUp-link {
    font-size: 125%;
    color: var(--button-color);
    margin-left: 5px;
  }
}


@media (max-width : 650px) {
  .LoginForm-container {
    flex-direction: column;
    background: url(../images/waves-2.jpg) no-repeat;
    background-size: cover;
  }
  .LoginForm-content2 ,
.LoginForm-content1{
  width: 100%;
  align-items: center;
}
.LoginForm-content2 {
  padding-bottom: 10%;
}
.login-form-container {
  width: 95%;
  margin: auto;
}
.login-form-group {
  margin-bottom: 15px;
  & label {
    padding: 5px 0;
  }
}
.LoginForm-Animation {
  & img {
    width: 120px;
    height: 110px;
  }
}
}