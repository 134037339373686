.menubar {
  max-width: 230px;
  min-width: 81px;
  height: 100%;
  padding: 8px 12px 20px;
  box-sizing: border-box;
  border-radius: 0 30px 30px 0;
  border-right-style: solid;
  border-right-color: rgb(219, 219, 219);
  border-right-width: 1px;
  display: flex;
  justify-content: center;
  background: var(--color);
}
.menubar-content {
  display: flex;
  flex-direction: column;
}
.nav__logo{
  width: 100px;
  height: 2rem;
}
.menubar-profile {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.menubar-profile-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
}
.menubar-profile-cont {
  display: flex;
  flex-direction: column;
  & p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 70%;
  }
  & h5 {
    cursor: pointer;
  }
}
.location-color {
  & p {
    color: var(--error-color);
  }
}
.menubar-checkIn {
  width: 100%;
  margin-bottom: 7px;
  & button {
    width: 100%;
  }
}

.menubar-menu-div {
  flex-grow: 1;
}

.menubar-menu {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
  color: var(--initial-color);
  padding: 5px;
  cursor: pointer;
  & h5 {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-weight: 500;
  }
}
.menubar-icons-notification-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-top: -8px;
  margin-left: 8px;
  background-color: red;
  border-radius: 50%;
}

.menubar-company-heading {
  margin-top: 7px;
  color: var(--sub-heading-faded-color);
}
.menubar-company {
  margin: 5px 0;
  max-height: 220px;
  overflow: scroll;
}
.menubar-company-content {
  margin-top: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 3px;
  & h5 {
    color: var(--active-color);
  }
  &:active {
    & h5 {
      color: unset;
    }
  }
}
.menubar-company-content-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.menubar-services {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.menubar-services-content {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--sub-heading-faded-color);
}
.menubar-services-content-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
}

.MainBar-Pad-Mar {
  height: 100%;
  padding: 0 0.7%;
  overflow-y: scroll;
}

/* --------------------------- side bar close css ---------------------------------- */
.menubar-half {
  border-radius: 0px !important;
    border: none;
    margin-right: 0;
    box-shadow: none !important;
  & .menubar-profile-cont {
    display: none;
  }
  & .menubar-menu,
  .menubar-company-content,
  .menubar-services-content {
    justify-content: center;
    & h5 {
      display: none;
    }
  }
  & .menubar-services-content {
    align-items: center;
  }
  & .menubar-close-open {
    margin-left: 115%;
  }
}

/* --------------------- heading active ------------------------ */
.content-active {
  background-color: var(--button-color);
  border-radius: 5px;
  border: none;
  color: var(--color);
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  &:hover {
    transition: 0.5s;
    background: var(--button-hover-color);
  }
  & button {
    color: var(--color);
  }
}

/* --------------------------- sub side menubar ---------------------------------- */
.sub-menubar-none {
  display: none;
}
.sub-menubar-container {
  width: -webkit-fill-available;
  height: 100%;
  position: absolute;
  left: 88px;
  top: 0;
  z-index: 1;
}
.sub-menubar {
  width: 250px;
  height: 100%;
  padding: 0px 10px;
  border-left-style: dotted;
  border-left-color: rgb(219, 219, 219);
  border-left-width: 0.1px;
  background: white;
  border-radius: 0 30px 30px 0;
  border-right-style: solid;
  border-right-color: rgb(219, 219, 219);
  border-right-width: 1px;
  box-sizing: border-box;
  overflow-y: scroll;
}


.menubar-top-768,
.menubar-bottom-768 {
  display: none;
}
.logout-popup {
  min-width: 400px;
  max-width: 630px;
  max-height: 500px;
  color: rgb(199, 0, 0);
  text-align: center;
  & h4 {
    margin: 4px 0;
    padding: 10px 0 5px 0;
    border-bottom-style: solid;
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-width: 1px;
    cursor: pointer;
    &:last-child {
      color: var(--active-color);
      border-bottom: unset;
    }
  }
}





.menubar-top-768 {
  width: 95%;
  padding: 0 2.5%;
}
.menubar-top-768-row {
  display: flex;
  gap: 5%;
  flex-grow: 1;
  justify-content: right;
}
.menubar-top-768-profile {
  width: fit-content;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.menubar-bottom-768 {
  border-top-style: solid;
  border-top-color: rgb(219, 219, 219);
  border-top-width: 1px;
  position: fixed;
  bottom: 0;
  background-color: white;
}


.lunchPop {
  position:fixed;
  width: 100%;
  height: 100%;
  left: 200px;
  top: 0;
}

.lunchpop-container {
  position: relative;
  min-width: 0px;
  min-height: 0px;
  max-width: 200px;
  max-height: 200px;
  padding: 3px;
  text-align: center;
  top: 15%;
  background: linear-gradient(to top, white , rgb(194, 224, 236) ) !important;
  & div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 3px 0px;
  }
  & label{
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  & p {
    color: var(--error-color);
    font-size: 75%;
    text-align: center;
  }
  & button {
    margin: 3px 0;
  }
}
.LandingPage-display {
  display: flex;
}


/* --------------------------- media query to hide side bar ---------------------------------- */
@media (max-width: 768px) {
  .menubar {
    display: none;
  }
  .menubar-top-768,
  .menubar-bottom-768 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;
    height: 45px;
  }
  .menubar-bottom-768 {
    width: 100%;
  }
  .MainBar-Pad-Mar {
    margin-bottom: 48px;
    padding: 0.5%;
  }
  .lunchPop {
    left: unset;
    background: var(--glass-background-color);
  }
  .lunchpop-container {
    top: unset;
    bottom: 0;
    min-width: 98%;
    max-width: 99%;
    min-height: 16%;
    position: absolute;
    border-radius: 30px 30px 0 0;
    padding: 15px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(to top, white , rgb(194, 224, 236) ) !important;
    & div {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    & label {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
    & input {
      width: 100%;
    }
    & p {
      width: 95%;
      margin: 5px auto;
      text-align: end;
    }
    & button {
      width: 100%;
    }
  }
}

@media (max-width : 480px) {
  .logout-popup {
    min-width: 90%;
    max-width: 90%;
    margin: auto;
  }
  .LandingPage-display {
    display: unset;
  }
}