* {
  margin: 0;
}
:root {
  --boxShadow-thin: inset 0 0 0 max(1px, 0.0625rem);
  --boxShadow-thick: inset 0 0 0 max(2px, 0.125rem);
  --borderColor-default: #d0d7de;
  --borderRadius-small: 0.1875rem;
  --borderRadius-medium: 0.375rem;
  --borderRadius-large: 0.75rem;
  --borderRadius-full: 624.9375rem;
  --background-hover-color: rgb(231, 231, 231);
  --background-color: rgb(239, 239, 239);
  --button-color:rgba( 0, 149, 246);
  --button-hover-color : #0087e1;
  --color: rgb(255, 255, 255);
  font-family: sans-serif;
  --shadow-resting-medium: 0px 3px 6px 0px #424a531f;
  --initial-color: rgb(113, 113, 113);
  --active-color: rgb(71, 71, 71);
  --sub-heading-color: rgb(53, 53, 53);
  --sub-heading-faded-color: rgb(159, 159, 159);
  --glass-background-color : rgba(0, 0, 0, 0.2);
  --error-color : rgb(216, 65, 65);
  --sucess-color :rgb(100, 216, 65);
  --pop-font-weight : 600;
  --cancel-color : rgb(230, 96, 96);
  --cancel-hover-color : rgb(214, 57, 57);
}

.container-background {
  background: var(--light-grad-pink-blue, radial-gradient(ellipse at 40% 0%, #bf398910 0, transparent 75%), radial-gradient(ellipse at 60% 0%, #096bde10 0, transparent 75%));
  box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
  border-radius: var(--borderRadius-medium, 6px);
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.container-boxShadow {
  box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
  border-radius: var(--borderRadius-medium, 6px);
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.container-border{
  box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
  border-radius: var(--borderRadius-medium, 6px);
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.border {
  border-radius: var(--borderRadius-medium, 6px);
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.button {
  background-color: var(--button-color);
  padding: 7px 13px;
  border-radius: 5px;
  border: none;
  color: var(--color);
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  &:hover {
    transition: 0.5s;
    background: var(--button-hover-color);
  }
}

.cancel-button {
  background-color: var(--cancel-color);
  padding: 7px 13px;
  border-radius: 5px;
  border: none;
  color: var(--color);
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
  &:hover {
    transition: 0.5s;
    background: var(--cancel-hover-color);
  }
}

.box-shadow {
  box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
}

.active-content {
  color: var(--active-color);
  font-weight: bolder;
}

.content-hover:hover {
  background-color: var(--background-hover-color);
  border-radius: 7px;
  &:active {
    color: var(--color);
    background: var(--button-hover-color);
  }
}

.scroll-bar {
  &::-webkit-scrollbar {
    display: none;
}
}
.popupContainer-center ,
.popupContainer-center2,
.popupContainer-center3{
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background: var(--glass-background-color);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupContainer-center-sub,
.popupContainer-center-sub2,
.popupContainer-center-sub3 {
  background: white;
  border-radius: 13px;
  padding: 2%;
  min-width: 600px;
}

.popupContainer-center-sub3 {
  & h4 {
    text-align: center;
    padding: 10px 0;
  }
}

.nothing-button {
  background: unset;
  border: unset;
  width: unset;
  height: unset;
  font: unset;
  color: unset;
  cursor: pointer;
}


.bottom-popup-container {
  width: 97%;
  margin: auto 0.7%;
  padding: 5px;
  display: flex;
  overflow: scroll;
  position: fixed;
  bottom: 47.5px;
  left: 0;
  justify-content: space-evenly;
  border-radius: var(--borderRadius-medium, 6px);
  background: var(--color);
}

.side-pop-message {
  max-width: 50%;
  font-size: 75%;
  font-weight: var(--pop-font-weight);
  position: fixed;
  top: 10%;
  right: 0;
  background: var(--color);
  padding: 15px 15px 15px 20px;
  border-radius: 25px 0 0 25px;
  box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.error-message {
  color: var(--error-color);
}

.sucess-message {
  color: var(--sucess-color);
}
.form-group {
  display: flex;
  flex-direction: column;
  
  margin-bottom: 25px;
  & label {
    color: var(--active-color);
    font-weight: 200;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 10px 0;
  }
  & input, textarea, select {
    border: none;
    border-bottom-style: solid;
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-width: 1.2px;
    outline: none;
    padding: 5px;
    background: transparent;
    color: var(--initial-color);
  }
}

.form-group2 {
  display: flex;
  flex-direction: column;
  
  margin-bottom: 15px;
  & label {
    color: var(--active-color);
    font-weight: 200;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 10px;
  }
  & input, textarea, select {
    border: none;
    border-bottom-style: solid;
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-width: 1.2px;
    outline: none;
    padding: 5px;
    background: transparent;
    color: var(--initial-color);
  }
}
.form-group3 {
  max-width: 700px;
  padding-bottom: 10px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  & div {
    display: flex;
    flex-direction: column;
    &:nth-child(even){
      margin-left: 5%;
    }
  }
  & button {
    grid-column: span 2;
  }
}
.form-group4 {
  max-width: 95%;
  display: unset;
  & .grid-div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    &:nth-child(even) {
      margin-left: unset;
    }
  }
  & span {
    display: flex;
    flex-direction: column;
    margin: 0 5px;
  }
  & .adduserCheckbox-TS {
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    & label {
      margin-top: 0;
    }
  }
  & button {
    width: 100%;
    margin: 3px 0;
  }
  & img {
    margin: auto;
  }
}
  .profile-form-Top {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
      & .profile-form-icon {
          border-radius: 50%;
          background: var(--button-color);
          padding: 7px;
        }
        background: var(--glass-background-color);
        display: flex;
        align-items: center;
        gap: 7px;
        padding-left: 10px;
      border-radius: 25px;
      height: fit-content;
      color: var(--initial-color);
      margin-top: 3px;
  }

/* ------------------------------ tables - reports ---------------------------------- */
.Reports-table {
  margin: 2% 0;
  width: 100%;
  color: var(--initial-color);
  font-weight: 900;
  font-size: 70%;
  max-height: 100%;
  text-align: center;
  & tbody {
    height: 100%;
    overflow: scroll; 
  }
}

.Reports-tr {
  height: 40px;
  border-bottom-style: solid;
  border-bottom-color: rgb(219, 219, 219);
  border-bottom-width: 1px;
  & span {
      white-space: nowrap;
      font-size: 75%;
  }
}

/* -------------------------- 768 popup containers ---------------------------------- */

.popupContainer768 {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  background: var(--glass-background-color);
  height: 100%;
}

.popupContainer768-
.popupContainer768-sub {
  width: 85%;
  margin: auto;
  max-height: 80%;
  overflow-y: scroll;
}
.popupContainer768-container {
  width: 100%;
  background: white;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 97%;
  overflow: scroll;
}

.bottom-popup {
  display: none;
  width: 100%;
  height: 95%;
  position: absolute;
  top: 0;
}


/* ------------------ react paginate ------------------------ */
.paginat {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
  padding: 0;
  & li {
    display: inline-block;
    margin: 0 5px;
  }
  & a {
    color: #007bff;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s;
    cursor: pointer;
  &:active {
    background-color: #007bff;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
    &:hover {
      background-color: #e7e7e7;
    }
  }
}

/* ------------------ reports-top-filters -------------------- */
.reports-top-filters {
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: right;
  & input, select {
    border-radius: var(--borderRadius-medium, 6px);
    border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
    padding: 5px;
  }
}

@media (max-width : 768px) {
  .mainBar-container {
    margin-left: -2px;
  }
  .popupContainer768-container {
      width: 90%;
      padding: 4.5%;
      border-radius: 13px;
      max-height: 100%;
  }
  .popupContainer768,.bottom-popup {
      display: unset;
  }
  .side-pop-message-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 70px;
  }
  .side-pop-message {
    max-width: 60%;
    overflow: hidden;
    position: unset;
    top: unset;
    background: var(--color);
    padding: 15px 15px 15px 20px;
    border-radius: 25px;
    filter: unset;
    box-shadow: var(--shadow-resting-medium, var(--color-shadow-medium));
    border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
  }
  .popupContainer-center {
    display: none;
  }
  .popupContainer-center-sub3 {
    top: unset;
    bottom: 0;
    min-width: 98%;
    max-width: 99%;
    min-height: 16%;
    position: absolute;
    border-radius: 30px 30px 0 0;
    padding: 15px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(to top, white , rgb(194, 224, 236) ) !important;
  }
  .popupContainer-center-sub3 {
    min-width: 80%;
  }
  .form-group3 {
    display: unset;
    & div {
      width: 90%;
      margin: auto;
  }
  & button {
    width: 90%;
    margin: 5px 5%;
  }
  }
  .bottom-height-768 {
    height: 100px;
  }
  .form-group4 {
  .grid-div {
    grid-template-columns: repeat(2, 1fr);
  }
}
.popup-768-sub3 {
  min-width: 99% !important;
}
}

@media (max-width : 480px) {
  .popupContainer768-container {
      width: 95%;
      padding: 2.5%;
      border-radius: 0 0 13px 13px ;
  }
  .popupContainer768-sub {
      width: 100%;
  }
  .side-pop-message {
    font-size: 65%;
  }
  .popupContainer-center-sub3 {
    min-width: 90%;
  }
  .form-group4 {
    .grid-div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .popup-768-sub3 {
    min-width: 99% !important;
  }
}