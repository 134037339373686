.containerer {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-grouper {
    margin-bottom: 20px;
  }
  
  .forgetlabel {
    display: block;
    margin-bottom: 5px;
  }
  
  .forgetinput[type='email'],
  .forgetinput[type='text'],
  .forgetinput[type='password'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .buttoner {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .errorere {
    color: red;
    margin-top: 5px;
  }
  