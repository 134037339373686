  .steps-progress {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    overflow: hidden;
  }
  .step {
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    color: var(--initial-color);
  }
  .step.active {
    color: var(--button-color);
  }

  .prev-next-btn {
    display: flex;
    justify-content: space-between;
  }

  .admin-signup-form {
    max-width: 100%;
    margin: 3%;
    height: 65%;
    padding: 3%;
    & form {
      height: 100%;
      display: flex;
      justify-content: space-around;
      & label {
        padding: 0;
      }
      & input {
        padding: 5px 0;
      }
    }
  }

  @media (max-width : 768px) {
    .steps-progress {
      width: 100%;
      overflow-x: scroll;
      gap: 10px;
    }
    .step {
      width: 100%;
      font-size: 70%;
      gap: 5px;
    }
  }