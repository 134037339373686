.Reports-Top {
    width: 95%;
    margin: 3% auto 0;
    display: flex;
    justify-content: space-between;
    color: var(--initial-color);
    & h5 {
        & .Report-top-icon {
            color: var(--color);
            font-size: unset;
            padding: 7px;
            border-radius: 50%;
            background: var(--button-color);
        }
        background: var(--glass-background-color);
        display: flex;
        align-items: center;
        gap: 7px;
        padding-right: 15px;
        cursor: default;
        border-radius: 25px;
        height: fit-content;
    }
    & button {
        font-size: 70%;
    }
  }

  

.Daily-reperts-heading {
    color: var(--button-color);
    width: fit-content;
margin: 15px 0;
padding: 10px 45px 10px 25px;
background: var(--glass-background-color);
  border-radius: 5px 50px 50px 5px;
  border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
}

.Daily-reperts {
    max-width: 1000px;
    margin:  5% auto;
    color: var(--initial-color);
    & label {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 90%;
        margin-top: 7px;
    }
    & input, select, textarea {
        padding: 10px;
        margin-top: 7px;
        border-radius: var(--borderRadius-medium, 6px);
        border: var(--borderWidth-thin, 1px) solid var(--borderColor-default, var(--color-border-default));
        outline: none;
    }
    & div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & label {
            width: 47%;
        }
        & span {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            align-items: center;
            & label {
                width: 100%;
                flex-direction: row;
                align-items: center;
                gap: 5%;
            }
            & input {
                margin: 0;
            }
        }
    }
    & textarea {
        resize: vertical;
    }
    & button {
        width: 90%;
        margin: 15px 5% 0;
    }
}
.daily-roprts-progress-bar {
    color: var(--error-color);
}

@media (max-width : 480px) {
    .Daily-reperts {
        width: 95%;
        margin: auto auto 10%;
        & div {
            width: 100%;
            flex-direction: column;
            & label {
                width: 100%;
            }
        }
    }
}